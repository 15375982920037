<template>
    <b-overlay :show="formShow" rounded="sm" no-fade class=" mt-2">
      <b-card>
        <b-row>
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">User</th>
              </tr>
            </thead>
          </table>
        </b-row>
  
        <validation-observer ref="userRules">
          <b-form>
            <b-row class="mt-2">
              <!-- Name -->
              <b-col cols="12" sm="6" md="4" xl="3">
                <b-form-group>
                  <label for="Name">Name</label>
                  <validation-provider name="Name" #default="{ errors }" rules="required">
                    <b-form-input id="name" type="text" v-model="userValue.name"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
  
              <!-- Surname -->
              <b-col cols="12" sm="6" md="4" xl="3">
                <b-form-group label="Surname" label-for="surname">
                  <validation-provider name="Surname" #default="{ errors }" rules="required">
                    <b-form-input id="surname" type="text" v-model="userValue.surname"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
  
  
        
              <!-- Email -->
              <b-col cols="12" sm="6" md="4" xl="3">
                <b-form-group label="Email" label-for="email">
                  <validation-provider name="Email" #default="{ errors }" rules="required|email">
                    <b-form-input id="email" type="text" :disabled="userId > 0" v-model="userValue.email"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
  
    
  
              <!-- Password -->
              <b-col cols="12" sm="6" md="4" xl="3" v-if="userValue.id == null">
                <b-form-group label="Password" label-for="password">
                  <validation-provider name="Password" #default="{ errors }" rules="required">
                    <b-form-input id="password" type="text" v-model="userValue.password"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              


            
  
  
  
  
  

              <b-col cols="12" class="mt-5">
                <b-button v-if="userId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                  Save
                </b-button>
                <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                  Save Changes
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <image-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />
      </b-card>
    </b-overlay>
  </template>
  
  <script>
  import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
  import { ref, onUnmounted } from '@vue/composition-api';
  import flatPickr from 'vue-flatpickr-component';
  
  import store from '@/store';
  import helpdeskStoreModule from './helpdeskStoreModule';
  import ImageCropper from '@core/components/image-cropper/ImageCropper.vue';
  import { required, alphaNum, email } from '@validations';
  import Ripple from 'vue-ripple-directive';
  import vSelect from 'vue-select';
  import Cleave from 'vue-cleave-component';
  import 'cleave.js/dist/addons/cleave-phone.us';
  import router from '@/router';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import axiosIns from '@/libs/axios';
  
  export default {
    components: {
      BTab,
      BTabs,
      BCard,
      BAlert,
      BLink,
      BRow,
      BCol,
  
      vSelect,
      Cleave,
      ToastificationContent,
      ImageCropper,
      ValidationProvider,
      ValidationObserver,
      flatPickr
    },
  
    directives: {
      Ripple,
      'b-modal': VBModal,
    },
  
    setup() {
      const USER_APP_STORE_MODULE_NAME = 'helpdesk';
  
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, helpdeskStoreModule);
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });
    },

    props: {
        clientId: {
      type: Number,
      required: true,
    },
  
  },
  
    data: function() {
      var userValue = {
        name: null,
        surname: null,
        email: null,
        role: 'client',
        password: null,
        status: null,
  
        phone:null,
        birthday:null,
        address:null,
        postCode:null,
        city:null,
        country:null,
  
        team:null,
        employment:null,
        hired_on:null,
        client_id:null,
  
  
  
        companyLogo: null,
      };
  
      const statusOptions = [
        { label: 'Active', value: 'active' },
        { label: 'Passive', value: 'passive' },
      ];
  
      return {
        userValue: userValue,
        imageUrl: 'company.png',
        baseURL: store.state.app.baseURL,
        selectedImage: null,
        croppedImage: null,
        userData: JSON.parse(localStorage.getItem('userData')),
        required,
        alphaNum,
        email,
        dropdownLoading: false,
        mainProps: {
          blank: true,
          blankColor: '#777',
          width: 75,
          height: 75,
          class: 'm1',
        },
        companyList: [],
        percentCompleted: 0,
        roleOptions: [
        
        ],
  
        teamOptions: ['Group Tailor Made','Tailor Made Excel','Systems Tailor Made'],
        employmentOptions: ['Founder','Full Time','Part Time','Intern'],
  
  
        statusOptions,
        userId: null,
        formShow: false,
        permissions: [],
  
        options: {
          number: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          },
  
          percent: {
            numeral: true,
            numeralPositiveOnly: true,
            blocks: [2],
            prefix: '% ',
            rawValueTrimPrefix: true,
            numeralIntegerScale: 2,
          },
        },
      };
    },
  
    created() {
      this.getUserById();
    },
  
    watch: {
  
    },
  
    methods: {
  

  
  
  
      getUserById() {
        this.formShow = true;
  
        if (router.currentRoute.params.id) {
          this.userId = parseInt(router.currentRoute.params.id);
  
          store
            .dispatch('helpdesk/fetchHelpdeskLogin', { id: router.currentRoute.params.id })
            .then((response) => {
              this.userValue = response.data;
              this.userValue.companyLogo = null;
              if (this.userValue.company != undefined && this.userValue.company != '') {
                this.imageUrl = this.userValue.company.logoLink;
              }
  
              this.formShow = false;
            })
            .catch((error) => {
              if (error.response.status === 404) {
                this.userValue = undefined;
              }
            });
        } else {
          this.formShow = false;
        }
      },
  



  
      formSubmitted() {
        this.formShow = true;
  
        this.$refs.userRules.validate().then((success) => {
          if (success) {
            this.userValue.client_id = this.clientId

            if (router.currentRoute.params.id) {
              store.dispatch('helpdesk/updateClientLogin', this.userValue).then((response) => {
                if (response.status == 200) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ User Update Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });
  
                  setTimeout(function() {
                    this.formShow = false;
  
                    // window.location.reload(true);
                    router.push({ name: 'helpdesks' });
                  }, 100);
                }
              });
            } else {
              store
                .dispatch('helpdesk/userCheck', { email: this.userValue.email })
                .then((response) => {
                  if (response.data == 'no') {
                    store.dispatch('helpdesk/addClientLogin', this.userValue).then((response) => {
                      if (response.status == 200) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Successful',
                            text: '✔️ User Add Successful',
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                          },
                        });
  
                        setTimeout(function() {
                          this.formShow = false;
                          router.push({ name: 'helpdesks' });
                        }, 1000);
                      }
                    });
                  } else {
                    this.formShow = false;
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'This email exists',
                        text: 'Please enter another email address.',
                        icon: 'RefreshCcwIcon',
                        variant: 'warning',
                      },
                    });
                  }
                })
                .catch((error) => {});
            }
          } else {
            this.formShow = false;
          }
        });
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  </style>
  