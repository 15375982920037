<template>
  <div>
    <add-helpdesk-login></add-helpdesk-login>
  </div>
</template>

<script>
import AddHelpdeskLogin from './AddHelpdeskLogin.vue';

export default {
  components: {
    AddHelpdeskLogin,
  },

  data() {
    return {};
  },
};
</script>
